import { createElement } from 'react';
import dayjs from 'dayjs';
export function classNames(...args) {
    return args.filter(val => val).join(' ');
}
export const createAddressString = (deliveryAddress) => `${deliveryAddress.streetAddress}, ${deliveryAddress.postalCode} ${deliveryAddress.city}`;
export function formatText(text) {
    return text
        .split('**')
        .map((text, index) => index % 2 === 0
        ? text
        : createElement('span', { style: { fontWeight: 600 }, key: index }, text));
}
export function timeBeforeDelivery(t, promised, diff) {
    const timeWithDiff = dayjs(promised).add(diff, 'ms');
    const hoursFromNow = dayjs(timeWithDiff).diff(dayjs(), 'hour');
    const minutesFromNow = dayjs(timeWithDiff).diff(dayjs(), 'minute');
    const minutesLeft = minutesFromNow - hoursFromNow * 60;
    if (hoursFromNow > 0) {
        return `~${hoursFromNow}${t('time.hour.short')} ${minutesLeft}${t('time.minute.short')}`;
    }
    if (minutesLeft > 5) {
        return `~${minutesLeft}\xa0${t('time.minute.medium')}`;
    }
    return `<\xa05\xa0${t('time.minute.medium')}`;
}
export function timeWindow(start, end) {
    return `${time(start)} - ${time(end)}`;
}
export function detailedTimeWindow(start, end) {
    return `${dayjs(start).format('ddd, D MMM, HH:mm')} - ${dayjs(end).format('HH:mm')}`;
}
export const weekday = (date) => {
    let weekday = dayjs(date).format('dddd');
    const currentDayjsLang = dayjs.locale();
    // Icelandic translations by dayjs are adjusted on customer req.
    if (currentDayjsLang === 'is')
        weekday = weekday.slice(0, -2);
    return weekday;
};
export const isToday = (date) => dayjs().isSame(date, 'day');
export const isTomorrow = (date) => dayjs().add(1, 'day').isSame(date, 'day');
export const isYesterday = (date) => dayjs().subtract(1, 'day').isSame(date, 'day');
export const isTodayOrYesterday = (date) => isToday(date) || isYesterday(date);
export const isTodayOrTomorrow = (date) => isToday(date) || isTomorrow(date);
export const time = (date) => {
    return dayjs(date).format('HH:mm');
};
export const getDateMonthAndTime = (date) => dayjs(date).format('D MMM HH:mm');
export const hasTimeExpired = (date) => dayjs().isAfter(dayjs(date));
export const flipCoordinates = (coords) => [
    coords[1],
    coords[0]
];
export function formatDistance(dist) {
    return dist < 1000 ? `${Math.ceil(dist / 10) * 10}\xa0m` : `${Math.round(dist / 1000)}\xa0km`;
}
export function distanceBetweenPoints([lat1, lon1], [lat2, lon2]) {
    const R = 6371e3; // metres
    const a1 = lat1 * (Math.PI / 180);
    const a2 = lat2 * (Math.PI / 180);
    const dx = (lat2 - lat1) * (Math.PI / 180);
    const dy = (lon2 - lon1) * (Math.PI / 180);
    const a = Math.sin(dx / 2) * Math.sin(dx / 2) +
        Math.cos(a1) * Math.cos(a2) * Math.sin(dy / 2) * Math.sin(dy / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
}
export function getDeliveryTypeBody(serviceType, t) {
    switch (serviceType) {
        case 'delivery':
            return t('generic.delivery');
        case 'pickup':
            return t('generic.pickup');
        case 'delivery_pickup':
            return t('generic.deliveryAndPickup');
        default:
            return '';
    }
}
export function getDeliveryMethod(deliveryRequirements, inPerson, t) {
    const { idCheck, signature, deliveryInPerson } = deliveryRequirements || {};
    if (idCheck) {
        return t('orderDetails.deliveryRequirements.idCheck');
    }
    if (signature) {
        return t('orderDetails.deliveryRequirements.signature');
    }
    if (inPerson || deliveryInPerson) {
        return t('generic.inPerson');
    }
    return t('generic.outsideDoor');
}
