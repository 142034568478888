import { useFetch } from './hooks/useFetch';
export const DELIVERY_OPTIONS = {
    DO_NOTHING: 10,
    KNOCK: 20,
    DOORBELL: 30
};
export const receivedStatus = [0, 5, 10];
export const preDeliveryStatus = [20, 30, 40];
export const inProgressStatus = [80, 81, 82];
export const cancelledStatus = [90, 91, 93, 94];
export const failedStatus = [92, 100, 101, 102, 109, 110, 130, 147];
export const completedStatus = [120, 135, 140, 145];
export const failedOrCancelledStatus = [...failedStatus, ...cancelledStatus];
export const failedOrCancelledOrCompletedStatus = [...failedOrCancelledStatus, ...completedStatus];
export const useFetchTracking = (trackingId) => useFetch(`?trackingId=${trackingId}`);
export const useSavePreferences = (trackingId) => {
    return useFetch(`preferences?trackingId=${trackingId}`, 'POST');
};
export const useSaveFeedback = (trackingId) => {
    return useFetch(`feedback?trackingId=${trackingId}`, 'POST');
};
